import React, { useState } from 'react';
import '../../scss/indexV3/section12.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
function Section12() {
  const { t } = useTranslation(['newIndex']);
  const Questions = [
    { question: t('qa.one.q'), answer: t('qa.one.a') },
    {
      question: t('qa.two.q'),
      answer: t('qa.two.a', { interpolation: { escapeValue: false } }),
    },
    { question: t('qa.three.q'), answer: t('qa.three.a') },
    { question: t('qa.four.q'), answer: t('qa.four.a') },
  ];
  return (
    <div className="landingv3__section12">
      <Title />
      <div className="landingv3__section12-grid">
        {Questions.map((item) => (
          <Item {...item} />
        ))}
      </div>
    </div>
  );
}
function Item({ question = '', answer = '' }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="landingv3__section12-item">
      <div className="questionContainer" onClick={() => setIsOpen(!isOpen)}>
        <p>{question}</p>
        <FontAwesomeIcon
          icon={faChevronRight}
          color="#373737"
          className={!isOpen ? 'QA__toggleButton' : 'toggleButton_____Active'}
          style={{ marginLeft: 'auto', fontSize: '18px', marginTop: '10px' }}
        />
      </div>
      {isOpen ? (
        <div className="answerContainer" onClick={() => setIsOpen(!isOpen)}>
          {answer.split('<br>').map((x) => (
            <>
              <p>{x}</p>
              <br />
            </>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
function Title() {
  const { t } = useTranslation(['indexV3']);
  return <h2>{t('section12.title')}</h2>;
}
export default Section12;
