import React, { useState, useMemo } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  InputGroupAddon,
  Spinner,
  InputGroup,
  FormFeedback,
  InputGroupText,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import Recaptcha from 'react-recaptcha';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { InputWithLoader } from './DemoForm';

const NewsletterFormSchema = Yup.object().shape({
  // name: Yup.string()
  //   .required('Obligatorio'),
  email: Yup.string().email('correo invalido').required('Obligatorio'),
  // industry: Yup.string()
  //   .required('Obligatorio'),
  // captcha: Yup.string()
  //   .required('Obligatorio'),
});

function NewsletterLanding({ locale, onlyForm }) {
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(true);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true);
  const initialValues = useMemo(() => {
    const values = {
      // name: '',
      email: '',
      // industry: 'Bienes Raíces',
      // captcha: '',
    };

    return values;
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, actions) => {
      const sent = await subscribeToNewsletter(values);

      actions.setSubmitting(false);
    },
    validationSchema: NewsletterFormSchema,
  });

  const error = {
    es: 'Error en el envio.',
    en: 'Send failed.',
    pt: 'Send failed.',
  };

  const success = {
    es: 'Gracias por suscribirte',
    en: 'Thank you for subscribing.',
    pt: 'Thank you for subscribing.',
  };

  const subscribeToNewsletter = async (values) => {
    //  http://localhost:4000
    const response = await fetch(
      'https://enterprise.api.trato.io/newsletter/subscribe',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
    );

    if (response.ok) {
      toast.success(success[locale], {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'success',
      });
      // setSucceed(true)
    } else {
      toast.error(error[locale], {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'error',
      });
    }
  };

  const toggleCard = () => {
    setIsOpen((open) => !open);
  };

  const handleReCaptchaVerification = async (response) => {
    try {
      const formData = new FormData();

      formData.append('secret', '6Le3DaYZAAAAAFIxOegIykr0a3QMo-s35oyUkqhY');
      formData.append('response', response);

      const res = await fetch(
        'https://www.google.com/recaptcha/api/siteverify',
        {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData,
        }
      );

      const json = await res.json();

      // console.log({json})
      // if (json.success) {
      console.log('Habilitar boton');
      setIsSubmitEnabled(true);
      // formik.setFieldValue('captcha', 'filled')
      // }
    } catch (error) {
      console.log({ error });
      setIsSubmitEnabled(true);
    }

    toggleCard();
  };

  return (
    <div id="newsletterLanding" className="">
      {/* <div className="card bg-light rounded border"> */}
      {!onlyForm ? (
        <div className="text-center mb-2">
          <h2 className="d-inline-block">
            {t('newsletter.landingTittle1')}{' '}
            <span className="underline-primary font-weight-bold">
              {t('newsletter.landingTittle2')}
            </span>
          </h2>
        </div>
      ) : (
        ''
      )}
      {!onlyForm ? (
        <div className="text-center mb-2">
          <h4 className="text-muted">{t('newsletter.landingSubtitle')}</h4>
        </div>
      ) : (
        ''
      )}
      {/* <div className="card-body py-2 px-3"> */}
      <Form onSubmit={formik.handleSubmit} noValidate>
        {/* <InputGroup>
            <Input placeholder="username" />
            <InputGroupAddon addonType="append">
              <Button>Suscribete</Button>
            </InputGroupAddon>
          </InputGroup> */}
        <FormGroup className="mx-2">
          <InputGroup>
            <InputWithLoader
              loading={formik.isSubmitting}
              id="email"
              name="email"
              type="email"
              placeholder={t('newsletter.landingPlaceholder')}
              value={formik.values.email}
              invalid={formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                type="submit"
                className="w-100"
                disabled={!isSubmitEnabled}
              >
                {t('newsletter.landingButton')}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </Form>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}

export default NewsletterLanding;
