import React, { useState } from 'react';
import '../../scss/indexV3/section1.scss';
import img1 from '../../images/indexV3/GestionContratos.webp';
import img2 from '../../images/indexV3/FirmaDigital.webp';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import DemoModal from '../../components/DemoModal';
import { useLocalization } from 'gatsby-theme-i18n';
function Section1Child2() {
  const { t } = useTranslation(['indexV3']);
  const [modal, setModal] = React.useState(false);
  const handleModal = () => {
    setModal(!modal);
  };
  const content = [
    {
      title: t('indexV3:section1.sub.0.title'),
      text: t('indexV3:section1.sub.0.text'),
      id: 0,
      img: img1,
      button: (
        <Button
          label={t('indexV3:section1.sub.0.button')}
          customStyles={{
            height: '25px',
            color: '#1d3744',
            backgroundColor: '#fff',
            marginTop: '10px',
            fontSize: '11px',
          }}
          onClick={handleModal}
        />
      ),
    },
    {
      title: t('indexV3:section1.sub.1.title'),
      text: t('indexV3:section1.sub.1.text'),
      id: 1,
      img: img2,
      button: (
        <a href="https://firmadigital.trato.io/tratostarter">
          <Button
            label={t('indexV3:section1.sub.1.button')}
            customStyles={{
              height: '25px',
              color: '#fff',
              backgroundColor: '#1d3744',
              marginTop: '10px',
              fontSize: '11px',
            }}
          />
        </a>
      ),
    },
  ];
  const [activeItem, setActiveItem] = useState(0);
  return (
    <div className="landingv3__section1__child2--sub">
      {modal && <DemoModal modal={modal} handleModal={handleModal} />}
      {content.map((item) => (
        <Item
          {...item}
          activeKey={activeItem}
          handleClick={() => setActiveItem(item.id)}
        />
      ))}
    </div>
  );
}
function Item({
  title = '',
  text = '',
  img = '',
  id = 0,
  activeKey = 0,
  button = <></>,
  handleClick = () => {},
}) {
  const { defaultLang, locale, localizedPath } = useLocalization();
  return (
    <div
      className={`section1__child2-item ${
        id === activeKey ? 'section1__child2-item--active' : ''
      }`}
      key={id}
    >
      <img src={img} alt="" className="image" width={400} />

      <div className={`child2--textItem child2--textItem${id + 1}`}>
        <div
          className={`titleContainer ${
            locale === 'es' ? 'titleEs' : 'titleEn'
          }`}
          onClick={handleClick}
        >
          <h3>{title}</h3>
        </div>
        {id === activeKey ? (
          <div className="textContainer">
            <p>{text}</p>
            {button}
          </div>
        ) : (
          ''
        )}
        <div className="textContainerMobile">
          <p>{text}</p>
          {button}
        </div>
      </div>
    </div>
  );
}
export default Section1Child2;
