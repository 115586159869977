import React, { useState, useLayoutEffect, useEffect } from 'react';
import Section1 from '../components/indexV3/Section1';
import Section2 from '../components/indexV3/Section2';
import Section3 from '../components/indexV3/Section3';
import Section4 from '../components/indexV3/Section4';
import Section5 from '../components/indexV3/Section5';
import Section6 from '../components/indexV3/Section6';
import Section7 from '../components/indexV3/Section7';
import Section8 from '../components/indexV3/Section8';
import Section9 from '../components/indexV3/Section9';
import Section10 from '../components/indexV3/Section10';
import Section11 from '../components/indexV3/Section11';
import Section12 from '../components/indexV3/Section12';
import Section13 from '../components/indexV3/Section13';
import SectionLayout from '../components/indexV3/SectionLayout';
import '../scss/indexV3/index.scss';
import '../scss/newIndex/newIndex.css';
import Header from '../components/HeaderV3';
import SubFooter from '../components/SubFooter';
import Footer from '../components/newIndexFooter';
import SEO from '../components/SEO';
import CookiesConsent from '../components/CookiesConsent';
import Helmet from 'react-helmet';
import useScriptV2 from '../hooks/useScriptV2';
import { useLocalization } from 'gatsby-theme-i18n';
import DemoModal from '../components/DemoModal';

function Index() {
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  const section1Styles = {
    background: 'linear-gradient(90deg, #f7f7f7 10%, #dadbdb 70%)',
    minHeight: '654px',
  };
  const section2Styles = {
    background: '#fff',
    minHeight: '250px',
  };
  const section3Styles = {
    background: '#fff',
    minHeight: '650px',
  };
  const section3ChildStyles = {
    padding: '0px 90px 40px',
  };
  const section4Styles = {
    background: '#fff',
    minHeight: '380px',
    padding: '0px 0px 60px',
  };
  const section5Styles = {
    background: '#f1f5f5',
    minHeight: '660px',
    padding: '50px 0px 70px',
  };
  const section6Styles = {
    background: '#fff',
    minHeight: '600px',
    padding: '60px 0px 50px',
  };
  const section7Styles = {
    background: '#f1f5f5',
    minHeight: '600px',
    padding: '40px 0px 40px',
  };
  const section8Styles = {
    background: '#fff',
    minHeight: '700px',
  };
  const section9Styles = {
    background: '#f1f5f5',
    minHeight: '660px',
    padding: '70px 0px 70px',
  };
  //
  const section10Styles = {
    background: '#fff',
    minHeight: '500px',
  };
  const section11Styles = {
    background: '#fff',
    minHeight: '250px',
  };
  const section12Styles = {
    background: '#fff',
    minHeight: '400px',
    padding: '0px 0px 80px',
  };
  const section13Styles = {
    background: '#1d3744',
    minHeight: '760px',
    padding: '70px 0px 0px',
  };
  const content = [
    {
      parentStyles: section1Styles,
      parentChildStyles: {},
      children: <Section1 handleDemo={handleModal} />,
    },
    {
      parentStyles: section2Styles,
      parentChildStyles: {},
      children: <Section2 />,
    },
    {
      parentStyles: section3Styles,
      parentChildStyles: section3ChildStyles,
      children: <Section3 />,
    },
    {
      parentStyles: section4Styles,
      parentChildStyles: {},
      children: <Section4 handleDemo={() => handleModal()} />,
    },
    {
      parentStyles: section5Styles,
      parentChildStyles: {},
      children: <Section5 />,
    },
    {
      parentStyles: section6Styles,
      parentChildStyles: {},
      children: <Section6 />,
    },
    {
      parentStyles: section7Styles,
      parentChildStyles: {},
      children: <Section7 />,
    },
    {
      parentStyles: section8Styles,
      parentChildStyles: {},
      children: <Section8 />,
    },
    {
      parentStyles: section9Styles,
      parentChildStyles: {},
      children: <Section9 />,
    },
    {
      parentStyles: section10Styles,
      parentChildStyles: {},
      children: <Section10 />,
    },
    {
      parentStyles: section11Styles,
      parentChildStyles: {},
      children: <Section11 />,
    },
    {
      parentStyles: section12Styles,
      parentChildStyles: {},
      children: <Section12 />,
    },
    {
      parentStyles: section13Styles,
      parentChildStyles: {},
      children: <Section13 />,
    },
  ];
  const { defaultLang, locale, localizedPath } = useLocalization();
  const [hasAccepted, setHasAccepted] = useState(false);

  useLayoutEffect(() => {
    const cookies = Boolean(localStorage.getItem('hasAccepted')) || false;
    if (cookies) {
      setHasAccepted(cookies);
    }
  }, []);

  useScriptV2({
    src: 'https://leadbooster-chat.pipedrive.com/assets/loader.js',
    isDisabled: !hasAccepted,
    locale,
  });

  return (
    <div id="landingv3">
      <SEO title="Gestión de contratos y firma electrónica" locale={locale} />

      <Helmet>
        <meta
          name="description"
          content="Trato es la mejor opción para gestionar tus contratos y firmar electrónicamente. Es un software basado en blockchain en la nube que simplifica los contratos para que las empresas tengan un mejor control y trazabilidad."
        />
      </Helmet>
      {!hasAccepted && (
        <CookiesConsent handleContinue={() => setHasAccepted(true)} />
      )}
      {modal && <DemoModal modal={modal} handleModal={handleModal} />}
      <Header handleModal={handleModal} handleHasScrolled={() => {}} />
      {content.map(({ children, parentChildStyles, parentStyles }) => (
        <SectionLayout
          parentStyles={parentStyles}
          childrenStyles={parentChildStyles}
        >
          {children}
        </SectionLayout>
      ))}
      <Footer />
      <div className="newIndex__subFooter">
        <SubFooter />
      </div>
    </div>
  );
}

export default Index;
