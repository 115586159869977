import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import '../../scss/indexV3/list.scss';
function List({ items = [{ text: '' }] }) {
  return (
    <div className='landingv3__list'>
      {items.map((item) => (
        <Item {...item} />
      ))}
    </div>
  );
}
function Item({ text = '' }) {
  return (
    <div className='landingv3__list-item'>
      <div className='checkContainer'>
        <FontAwesomeIcon icon={faCheck} color='#fff' size='xs' />
      </div>
      <div>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default List;
