import React from 'react';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/indexV3/100pro.svg';

import img3 from '../../images/indexV3/ISO_AWS.svg';
function Section11() {
  const { t } = useTranslation(['indexV3']);
  const content = [
    {
      src: img1,
    },
    {
      src: img3,
    },
  ];
  return (
    <div className="landingv3__section11">
      <h2>{t('section11.title')}</h2>
      <div className="landingv3__section11-row">
        {content.map(({ src }) => (
          <img className="image" src={src} width={200} />
        ))}
      </div>
    </div>
  );
}

export default Section11;
