import React from 'react';
import '../../scss/indexV3/section8.scss';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/indexV3/Herramientas1.webp';
import img2 from '../../images/indexV3/Herramientas2.webp';
import img3 from '../../images/indexV3/Herramientas3.webp';
import img4 from '../../images/indexV3/Herramientas4.webp';

import Button from './Button';
import DemoModal from '../../components/DemoModal';
function Section8() {
  const { t } = useTranslation(['indexV3']);
  const [modal, setModal] = React.useState(false);
  const handleModal = () => {
    setModal(!modal);
  };
  const grid1SubContent1 = [
    {
      title: t('section8.grid.0.title'),
      text: t('section8.grid.0.text'),
      styles: {
        width: 'calc(50% - 7.5px)',
      },
    },
    {
      title: t('section8.grid.1.title'),
      text: t('section8.grid.1.text'),
      styles: {
        width: 'calc(50% - 7.5px)',
        padding: '23px 30px 23px 23px',
      },
    },
    {
      title: t('section8.grid.2.title'),
      text: t('section8.grid.2.text'),
      styles: {
        width: '100%',
      },
    },
  ];
  const grid1SubContent2 = [
    {
      title: t('section8.grid.3.title'),
      text: t('section8.grid.3.text'),
      img: '',
      styles: {
        padding: '35px 25px 35px',
      },
    },
    {
      title: '',
      text: '',
      img: '',
      styles: {
        height: '188px',
        background: `url(${img3})`,
        backgroundSize: 'cover',
      },
    },
  ];
  const grid1SubContent3 = [
    {
      title: t('section8.grid.4.title'),
      text: t('section8.grid.4.text'),
      img: '',
      textColor: '#fff',
      styles: {
        backgroundColor: '#49a0ea',
      },
    },
    {
      title: t('section8.grid.5.title'),
      text: t('section8.grid.5.text'),
      img: '',
      textColor: '',
      styles: {
        paddingRight: '20px',
      },
    },
  ];
  const grid2SubContent1 = [
    {
      title: t('section8.grid.6.title'),
      text: t('section8.grid.6.text'),
      img: '',
      textColor: '#fff',
      styles: {
        backgroundColor: '#1d3744',
      },
    },
    {
      title: t('section8.grid.8.title'),
      text: t('section8.grid.8.text'),
      img: '',
      styles: {},
    },
    {
      title: '',
      text: '',
      img: '',
      styles: {
        height: '120px',
        background: `url(${img2})`,
        backgroundSize: 'cover',
      },
    },
    {
      title: t('section8.grid.10.title'),
      text: t('section8.grid.10.text'),
      img: '',
      styles: {},
    },
  ];
  const grid2SubContent2 = [
    {
      title: t('section8.grid.7.title'),
      text: t('section8.grid.7.text'),
      img: '',
      styles: {},
      button: false,
    },

    {
      title: '',
      text: '',
      img: '',
      styles: {
        height: '130px',
        background: `url(${img1})`,
        backgroundSize: 'cover',
      },
      button: false,
    },
    {
      title: t('section8.grid.9.title'),
      text: t('section8.grid.9.text'),
      img: '',
      textColor: '#fff',
      styles: {
        backgroundColor: '#373737',
      },
      button: false,
    },
    {
      title: '',
      text: '',
      img: '',
      styles: {
        height: '135px',
        background: `url(${img4})`,
        backgroundSize: 'cover',
      },

      button: (
        <Button
          label="Solicita Demo"
          customStyles={{
            width: '100%',
            height: '40px',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            borderRadius: '10px',
            color: '#fff',
            fontSize: '18px',
          }}
          onClick={handleModal}
        />
      ),
    },
  ];
  return (
    <div className="landingv3__section8">
      {modal && <DemoModal modal={modal} handleModal={handleModal} />}
      <h2>{t('section8.title')}</h2>
      <div className="landingv3__section8-child1">
        <div className="section8__grid1">
          <div className="section8__grid1--child1">
            {grid1SubContent1.map(({ title, text, styles }) => (
              <Item text={text} title={title} styles={styles} />
            ))}
          </div>
          <div className="section8__grid1--child2">
            <div className="children">
              {grid1SubContent2.map(({ title, text, styles }) => (
                <Item text={text} title={title} styles={styles} />
              ))}
            </div>
            <div className="children">
              {grid1SubContent3.map(({ title, text, styles, textColor }) => (
                <Item
                  text={text}
                  title={title}
                  styles={styles}
                  textColor={textColor}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="section8__grid2">
          <div className="children">
            {grid2SubContent1.map((item) => (
              <Item {...item} />
            ))}
          </div>
          <div className="children">
            {grid2SubContent2.map((item) => (
              <Item {...item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
function Item({
  title = '',
  text = '',
  img = '',
  button = false,
  styles = {},
  textColor = '',
  handleClick = () => {},
}) {
  return (
    <div
      className="section8--RowItem"
      style={{ padding: '23px', position: 'relative', ...styles }}
    >
      {img && <img src="" alt="" className="image" />}
      <h6 style={textColor ? { color: textColor } : {}}>{title}</h6>
      <p style={textColor ? { color: textColor } : {}}>{text}</p>
      {button && button}
    </div>
  );
}
export default Section8;
