import React from 'react';
import '../../scss/indexV3/section4.scss';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import img1 from '../../images/indexV3/Icon-Envia.svg';
import img2 from '../../images/indexV3/Icon-Firma.svg';
import img3 from '../../images/indexV3/Icon-Riesgos.svg';
function Section4({ handleDemo = () => {} }) {
  const { t } = useTranslation(['indexV3']);
  const child2Content = [
    {
      img: img2,
      title: t('section4.list.0.title'),
      text: t('section4.list.0.text'),
    },
    {
      img: img1,
      title: t('section4.list.1.title'),
      text: t('section4.list.1.text'),
    },
    {
      img: img3,
      title: t('section4.list.2.title'),
      text: t('section4.list.2.text'),
    },
  ];
  return (
    <div className="landingv3__section4">
      <div className="landingv3__section4-child1">
        <h2>{t('section4.child1.title')}</h2>
        <p>{t('section4.child1.text')}</p>
        <Button
          label={t('section4.child1.button')}
          className={'section4__btn'}
          onClick={handleDemo}
          customStyles={{
            width: '250px',
            height: '55px',
            color: '#fff',
            borderRadius: '10px',
            marginTop: '30px',
          }}
        />
      </div>
      <div className="landingv3__section4-child2">
        {child2Content.map((item) => (
          <TextItem {...item} />
        ))}
      </div>
    </div>
  );
}
function TextItem({ img = '', title = '', text = '' }) {
  return (
    <div className="landingv3__section4-child2--textItem">
      <img src={img} alt="" width={45} />

      <h5>{title}</h5>
      <span>{text}</span>
    </div>
  );
}
export default Section4;
