import React from 'react';
import '../../scss/indexV3/section13.scss';
import Button from './Button';
import { useTranslation } from 'react-i18next';
import DemoModal from '../../components/DemoModal';
function Section13() {
  const { t } = useTranslation(['indexV3']);
  const [modal, setModal] = React.useState(false);
  const handleModal = () => {
    setModal(!modal);
  };
  const buttonSharedStyles = {
    width: '350px',
    height: '100px',
    margin: '0px auto',
    borderRadius: '10px',
    fontSize: '20px',
  };
  const content = [
    {
      text: t('section13.boxes.0.text'),
      buttonText: t('section13.boxes.0.button'),
      buttonStyles: {
        ...buttonSharedStyles,
        color: '#fff',
      },
      onClick: () => {},
      isLink: true,
    },
    {
      text: t('section13.boxes.1.text'),
      buttonText: t('section13.boxes.1.button'),
      buttonStyles: {
        ...buttonSharedStyles,
        background: '#fff',
        color: '#1d3744',
      },
      onClick: handleModal,
      isLink: false,
    },
  ];
  return (
    <div className="landingv3__section13">
      {modal && <DemoModal modal={modal} handleModal={handleModal} />}
      <h2>{t('section13.title')}</h2>
      <div className="landingv3__section13-row">
        {content.map((item, i) => (
          <Item {...item} />
        ))}
      </div>
    </div>
  );
}

function Item({
  text = '',
  buttonText = '',
  buttonStyles = {},
  isLink,
  onClick = () => {},
}) {
  return (
    <div className="landingv3__section13-row--item">
      <div className="itemSquare">
        {Array.from(Array(4)).map((x, i) => (
          <div className={`corner${i + 1}`} key={`corner${i + 1}`}></div>
        ))}
        <p>{text}</p>
      </div>
      {isLink ? (
        <a href="/starter" style={{ margin: '0px auto' }}>
          <Button
            label={buttonText}
            customStyles={buttonStyles}
            onClick={onClick}
          />
        </a>
      ) : (
        <Button
          label={buttonText}
          customStyles={buttonStyles}
          onClick={onClick}
        />
      )}
    </div>
  );
}
export default Section13;
