import React from 'react';

function SectionLayout({
  parentStyles = {},
  childrenStyles = {},
  children = <></>,
}) {
  return (
    <div style={parentStyles} className='landingv3__sectionLayout'>
      <div style={childrenStyles} className='landingv3__sectionLayout-children'>
        {children}
      </div>
    </div>
  );
}

export default SectionLayout;
