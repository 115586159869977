import React from 'react';

function Button({
  customStyles = {},
  label = '',
  onClick = () => {},
  className = '',
}) {
  const buttonStyles = {
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    backgroundColor: '#f37b33',
    width: '130px',
    height: '70px',
  };
  return (
    <button
      style={{ ...buttonStyles, ...customStyles }}
      onClick={onClick}
      className={className}
    >
      {label}
    </button>
  );
}

export default Button;
