import React from 'react';
import '../../scss/indexV3/section1.scss';
import List from './List';
import { useTranslation } from 'react-i18next';
import Section1Child2 from './Section1Child2';
function Section1() {
  const { t } = useTranslation(['indexV3']);
  const ListContent = [
    {
      text: t('section1.list.0'),
    },
    {
      text: t('section1.list.1'),
    },
    {
      text: t('section1.list.2'),
    },
    {
      text: t('section1.list.3'),
    },
  ];
  return (
    <div className="landingv3__section1">
      <div className="landingv3__section1__child1">
        <h1>{t('section1.title')}</h1>
        <p>{t('section1.text')}</p>
        <List items={ListContent} />
      </div>
      <div className="landingv3__section1__child2">
        <Section1Child2 />
      </div>
    </div>
  );
}

export default Section1;
