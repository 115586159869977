import React from 'react';
import '../../scss/indexV3/section3.scss';
import { useTranslation } from 'react-i18next';
import image from '../../images/indexV3/Digitalizatufirma.webp';
import Img1 from '../../images/newIndex/page2/pics/Iconos/Icono (4).svg';
import Img2 from '../../images/newIndex/page2/pics/Iconos/Icono (5).svg';
import Img3 from '../../images/newIndex/page2/pics/Iconos/Icono (6).svg';
import Img4 from '../../images/newIndex/page2/pics/Iconos/Icono (7).svg';
import Img5 from '../../images/newIndex/page2/pics/Iconos/Icono (8).svg';
import Img6 from '../../images/newIndex/page2/pics/Iconos/Icono (9).svg';
function Section3() {
  const { t } = useTranslation(['indexV3']);
  const child2Content = [
    {
      imgSrc: Img1,
      text: t('section3.list.0'),
      link: '',
    },
    {
      imgSrc: Img2,
      text: t('section3.list.1'),
      link: '',
    },
    {
      imgSrc: Img3,
      text: t('section3.list.2'),
      link: '',
    },
    {
      imgSrc: Img4,
      text: t('section3.list.3'),
      link: '',
    },
    {
      imgSrc: Img5,
      text: t('section3.list.4'),
      link: '',
    },
    {
      imgSrc: Img6,
      text: t('section3.list.5'),
      link: '',
    },
  ];
  return (
    <div className="landingv3__section3">
      <div className="landingv3__section3-child1">
        <img src={image} className="image" width={550} />
      </div>
      <div className="landingv3__section3-child2">
        {child2Content.map(({ imgSrc, text, link }, i) => (
          <TextItem
            imgSrc={imgSrc}
            text={text}
            link={link}
            shortWidth={i !== child2Content.length - 1}
          />
        ))}
      </div>
    </div>
  );
}

function TextItem({ imgSrc = '', text = '', link = '', shortWidth = false }) {
  return (
    <div
      className={`${
        shortWidth ? 'landingv3__section3-child2--short' : ' '
      } landingv3__section3-child2--textItem`}
    >
      <img src={imgSrc} alt="" width={45} className="image" />
      <div>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Section3;
