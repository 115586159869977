import React from 'react';
import '../../scss/indexV3/section2.scss';

import Gennomalab from '../../images/newIndex/page2/gennomalab.svg';
import Samsung from '../../images/newIndex/page2/samsung.svg';
import Rappi from '../../images/newIndex/page2/rappi.svg';
import Invex from '../../images/newIndex/page2/invex.svg';
import Kavak from '../../images/newIndex/page2/kavak.svg';
import Ibm from '../../images/newIndex/page2/ibm.svg';
import Cabify from '../../images/newIndex/page2/cabify.svg';
// import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
function Section2() {
  const images = [
    { src: Gennomalab },
    { src: Samsung },
    { src: Rappi },
    { src: Invex },
    { src: Kavak },
    { src: Ibm },
    { src: Cabify },
  ];
  const { t } = useTranslation(['indexV3']);
  // const images = [
  //   {
  //     src: '../../images/newIndex/page2/estafeta.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/gennomalab.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/samsung.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/rappi.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/invex.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/kavak.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/ibm.svg',
  //   },
  //   {
  //     src: '../../images/newIndex/page2/cabify.svg',
  //   },
  // ];

  return (
    <div className="landingv3__section2">
      <h2>{t('section2.title')}</h2>
      <div className="landingv3__section2-row">
        {images.map(({ src }, i) => (
          <img
            src={src}
            width={110}
            alt="image"
            key={`image${i}`}
            loading="lazy"
          />
          // <StaticImage
          //   src={src}
          //   width={110}
          //   alt='image'
          //   key={`image${i}`}
          //   loading='lazy'
          // />
        ))}
      </div>
    </div>
  );
}

export default Section2;
