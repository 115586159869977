import React, { useState, useEffect, useRef } from 'react';
import '../../scss/indexV3/section7.scss';
import { useTranslation } from 'react-i18next';
import Wireframe1 from '../../images/newIndex/page2/pics/Wireframe_1 (1).svg';
import Reports from '../../images/newIndex/page2/Dash_Reportes (3).svg';
function Section7() {
  const { t } = useTranslation(['indexV3']);
  const images = [{ src: Reports }, { src: Wireframe1 }];
  const content = [
    {
      title: t('indexV3:section7.0.title'),
      text: t('indexV3:section7.0.text'),
    },
    {
      title: t('indexV3:section7.1.title'),
      text: t('indexV3:section7.1.text'),
    },
  ];
  // Scroll Listener
  const [activeItem, setActiveItem] = useState(0);
  const handleScroll = () => {
    const el = element.current;
    const location = el.getBoundingClientRect();
    console.log(location?.y, 'scroll');
    const positionY = location?.y;
    if (positionY > 160) {
      setTimeout(() => {
        setActiveItem(0);
      }, 30);
    } else {
      setTimeout(() => {
        setActiveItem(1);
      }, 30);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const element = useRef();

  return (
    <div className="landingv3__section7" ref={element}>
      <div className="landingv3__section7-child1-mobile">
        {content.map((item, i) => (
          <div>
            <TextItem {...item} active={i === activeItem} />
            <img
              src={images?.[i]?.src}
              alt=""
              width={650}
              className={'image'}
              key={`image${i}`}
            />
          </div>
        ))}
      </div>

      <div className="landingv3__section7-child1">
        {content.map((item, i) => (
          <TextItem {...item} active={i === activeItem} />
        ))}
      </div>
      <div className="landingv3__section7-child2">
        {images.map(({ src }, i) => (
          <img
            src={src}
            alt=""
            hidden={activeItem !== i}
            width={650}
            className={'image'}
            key={`image${i}${activeItem}`}
          />
        ))}
      </div>
    </div>
  );
}

function TextItem({ title = '', text = '', active = false }) {
  return (
    <div
      className={`landingv3__section7-child1--item ${
        active ? 'landingv3__section7-child1--item--active' : ''
      }`}
    >
      {active ? <div className="leftBar"></div> : ''}
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
}
export default Section7;
