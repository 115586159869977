import React from 'react';
import '../../scss/indexV3/section5.scss';
import { useTranslation } from 'react-i18next';
import Reportes1 from '../../images/newIndex/page2/Group 3481.svg';
import Reportes2 from '../../images/newIndex/page2/Group 3482.svg';
import Reportes3 from '../../images/newIndex/page2/Group 3483.svg';
function Section5() {
  const { t } = useTranslation(['indexV3']);
  const rowContent = [
    {
      img: Reportes3,
      title: t('section5.items.0.title'),
      text: t('section5.items.0.text'),
    },
    {
      img: Reportes1,
      title: t('section5.items.1.title'),
      text: t('section5.items.1.text'),
    },
    {
      img: Reportes2,
      title: t('section5.items.2.title'),
      text: t('section5.items.2.text'),
    },
  ];
  return (
    <div className="landingv3__section5">
      <h2>{t('section5.title')}</h2>
      <div className="landingv3__section5-row">
        {rowContent.map((item) => (
          <RowItem {...item} />
        ))}
      </div>
    </div>
  );
}

function RowItem({ img = '', title = '', text = '' }) {
  return (
    <div className="landingv3__section5-row--item">
      <img src={img} alt="" className="image" height={70} />
      <h6>{title}</h6>
      <p>{text}</p>
    </div>
  );
}

export default Section5;
