import React from 'react';
import '../../scss/indexV3/section6.scss';
import List from './List';
import { useTranslation } from 'react-i18next';
import image from '../../images/indexV3/ControlPreciso.webp';
function Section6() {
  const { t } = useTranslation(['indexV3']);

  const ListContent = [
    {
      text: t('section6.list.0'),
    },
    {
      text: t('section6.list.1'),
    },
    {
      text: t('section6.list.2'),
    },
    {
      text: t('section6.list.3'),
    },
  ];
  return (
    <div className="landingv3__section6">
      <div className="landingv3__section6-child1">
        <h2>{t('section6.title')}</h2>
        <p>{t('section6.text')}</p>
        <List items={ListContent} />
      </div>
      <div className="landingv3__section6-child2">
        <img src={image} className="image" width={650} />
      </div>
    </div>
  );
}

export default Section6;
