import React from 'react';

import { useTranslation } from 'react-i18next';
import Word from '../../images/newIndex/page2/page9/microsoft-word-seeklogo.com.png';
import Zoom from '../../images/newIndex/page2/page9/zoom-seeklogo.com-1.png';
import Slack from '../../images/newIndex/page2/page9/slack-technologies-seeklogo.com.png';
function Section9() {
  const { t } = useTranslation(['newIndex', 'home']);
  return (
    <div className="landingv3__section9">
      <div className="page--9__code">
        <div>
          <p>{'> $ npm install move.js'}</p>
          <ul>
            <li>Everything is installed</li>
          </ul>
          <p>{'> $ guip'}</p>
          <ul>
            <li>scss watching</li>
            <li>LiveReload Started</li>
            <li>Opening localhost:3000</li>
          </ul>
          <p>{'> $ drawillusion'}</p>
          <ul>
            <li>scss watching</li>
            <li>LiveReload Started</li>
            <li>Opening localhost:3000</li>
          </ul>
          <p>{'> $ filset'}</p>
          <ul>
            <li>Everything is installed</li>
          </ul>
          <p>{'> $ designstack'}</p>
          <ul>
            <li>Everything is installed</li>
          </ul>
          <p>{'> $ that´s it'}</p>
          <ul>
            <li>Yup, that´s it</li>
          </ul>
        </div>
      </div>
      <div className="landingv3__section9-child2">
        <h2>{t('pageeight.title')}</h2>
        <p>{t('pageeight.paragraph')}</p>
        <p>{t('pageeight.text')}</p>

        <div className="page--9__imgContainer">
          {/* <Img 
                fixed={data.zoomLogo.childImageSharp.fixed}
                alt="Zoom logo"
                // style={{width: "70%"}}
              /> */}
          {/* <Img 
                fixed={data.slackLogo.childImageSharp.fixed}
                alt="Slack logo"
                // style={{width: "70%"}}
              /> */}
          {/* <Img 
                fixed={data.wordLogo.childImageSharp.fixed}
                alt="Word logo"
                // style={{width: "70%"}}
              />  */}
          <img loading="lazy" src={Zoom} alt="" width={113} />
          <img loading="lazy" src={Slack} alt="" width={133} />
          {/* <img loading="lazy" src={data.zoomLogo.childImageSharp?.resize?.src} alt="" /> */}
          {/* <img loading="lazy" src={data.slackLogo.childImageSharp?.resize?.src} alt="" /> */}
          {/* <img loading="lazy" src={data.wordLogo.childImageSharp?.resize?.src} alt="" /> */}
          <img loading="lazy" src={Word} alt="" width={45} />
        </div>
      </div>
    </div>
  );
}

export default Section9;
