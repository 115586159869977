import React, { useEffect, useRef, useState } from 'react';
import '../../scss/indexV3/section10.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import NewsletterLanding from '../NewsletterLanding';
function Section10() {
  const { t } = useTranslation(['newIndex']);
  const items = [
    {
      defaultZIndex: 0,
      name: t('reviews.one.name'),
      category: t('reviews.one.p'),
      review: t('reviews.one.review'),
      stars: 5,
    },
    {
      defaultZIndex: 1,
      name: t('reviews.two.name'),
      category: t('reviews.two.p'),
      review: t('reviews.two.review'),
      stars: 5,
    },
    {
      defaultZIndex: 0,
      name: t('reviews.three.name'),
      category: t('reviews.three.p'),
      review: t('reviews.three.review'),
      stars: 5,
    },
  ];
  const [visibleElement, setVisibleElement] = useState(0);
  const parentRef = useRef();
  const handleScroll = () => {
    const el = parentRef.current;
    const elementWidth = el.offsetWidth;
    const location = el.scrollLeft;
    const spaceForElement = elementWidth / (items.length * 2.5);
    for (let i = 0; i < items.length; i++) {
      console.log(location, elementWidth, spaceForElement * i, 'comparasion');

      if (spaceForElement * i <= location) {
        setVisibleElement(i);
      }
    }
  };
  useEffect(() => {
    parentRef.current.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      parentRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="landingv3__section10">
      <div className="landingv3__section10-child1">
        <Title />
        <div className="landingv3__section10-child1--newsLetterLanding">
          <p>{t('¡Forma parte de nuestra comunidad!')}</p>
          <span>{t('Recibe información, consejos y mucho más.')}</span>
          <NewsletterLanding onlyForm />
        </div>
      </div>
      <div className="landingv3__section10-child2" ref={parentRef}>
        {items.map((item, i) => (
          <div
            className={`landingv3__section10-child2--item ${
              i === visibleElement
                ? 'landingv3__section10-child2--itemActive'
                : ''
            }`}
            style={i !== visibleElement ? { zIndex: item.defaultZIndex } : {}}
          >
            <div className="content">
              <p style={{ fontWeight: 'bold' }}>{item.name}</p>
              <p>{item.category}</p>
              <span style={{ marginTop: '30px' }}>{item.review}</span>
              <div className="row">
                {Array.from(Array(item.stars)).map((x) => (
                  <FontAwesomeIcon
                    icon={faStar}
                    color="#ffbe3d"
                    style={{ fontSize: '24px' }}
                  />
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
function Title() {
  const { t } = useTranslation(['indexV3']);
  return <h2>{t('section10.title')}</h2>;
}
export default Section10;
