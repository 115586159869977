import React, { useState, useEffect, useRef } from 'react';
import Logo from '../images/trato-logo.svg';
import '../scss/indexV3/headerV3.scss';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useLocalization } from 'gatsby-theme-i18n';
function NewHeader({ handleModal, handleHasScrolled, pageLoaded }) {
  const { defaultLang, locale, localizedPath } = useLocalization();
  console.log(locale);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setSrollPosition(position);
  };

  // Scroll Listener
  const [ScrollIs, SetScrolIs] = useState('Up');
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const prevCountRef = useRef();
  useEffect(() => {
    prevCountRef.current = scrollPosition;
    if (scrollPosition > prevCount) {
      SetScrolIs('Down');
      setIsOpen(false);
      if (!pageLoaded) {
        handleHasScrolled();
        console.log('HasScrolled');
      }
    } else {
      SetScrolIs('Up');
      setIsOpen(false);
    }
  }, [scrollPosition]);
  const prevCount = prevCountRef.current;

  // Scroll Listener
  return (
    <header
      className={ScrollIs === 'Up' ? 'headerV3' : 'headerV3 newHeaderHide'}
      style={{
        backgroundColor:
          scrollPosition < 100 && !isOpen ? 'transparent' : '#fff',
      }}
    >
      <div
        className={
          isOpen ? 'headerContentV2 newHeaderFixed' : 'headerContentV2'
        }
      >
        <div>
          <img src={Logo} alt="Logo" width={133} height={28} />
        </div>
        <div className="newHeader__menu">
          <div>
            <a href={'/'}> {locale === 'es' ? 'Inicio' : 'Home'} </a>
          </div>
          {locale === 'es' ? (
            <div>
              <a href="/templates">Plantillas</a>
            </div>
          ) : (
            ''
          )}
          {locale === 'es' ? (
            <div>
              <a href="https://blog.trato.io/">Blog</a>
            </div>
          ) : (
            ''
          )}
          {locale === 'es' ? (
            <div>
              <a href="/firma-electronica">Firma Electrónica</a>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="newHeader__lastBox">
          <a href="https://starter.trato.io/login">
            <div className="newHeader__LogIn">
              <span>{locale === 'es' ? 'Comienza Gratis' : 'Start Free'}</span>
            </div>
          </a>
          <div className="newHeader__Demo" onClick={handleModal}>
            <span>Demo</span>
          </div>
        </div>
        <div className="LanguageSwap">
          <LanguageSwitcher />
        </div>

        {/* Mobile Menu */}

        <div className="NewHeader__MobileMenu">
          <div
            className={isOpen ? 'Menu__container change' : 'Menu__container'}
            onClick={toggle}
          >
            <div className="Menu__bar1"></div>
            <div className="Menu__bar2"></div>
            <div className="Menu__bar3"></div>
          </div>
          {isOpen ? (
            <div
              className="Mobile__Menu"
              style={{
                backgroundColor:
                  scrollPosition < 100 && !isOpen ? 'transparent' : '#fff',
              }}
            >
              <div>
                <a href={'/'}> {locale === 'es' ? 'Inicio' : 'Home'} </a>
              </div>

              {locale === 'es' ? (
                <div>
                  <a href="/templates">Plantillas</a>
                </div>
              ) : (
                ''
              )}
              {locale === 'es' ? (
                <div>
                  <a href="https://blog.trato.io/">Blog</a>
                </div>
              ) : (
                ''
              )}
              {locale === 'es' ? (
                <div>
                  <a href="/firma-electronica">Firma Electrónica</a>
                </div>
              ) : (
                ''
              )}
              <a
                href="https://enterprise.app.trato.io/login"
                className="newHeader__LogInLink"
              >
                <div className="newHeader__LogIn">
                  <span>{locale === 'es' ? 'Iniciar Sesión' : 'LogIn'}</span>
                </div>
              </a>

              <div
                className="newHeader__Demo MobileMenu__demo"
                onClick={handleModal}
              >
                <span>Demo</span>
              </div>
              <div className="LanguageSwapMobile">
                <LanguageSwitcher />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </header>
  );
}

export default NewHeader;
