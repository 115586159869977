import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import classnames from 'classnames';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';


function useOutsideAlerter(ref, parentRef, cb) {
  function handleClickOutside(event) {
    if (ref.current
      && !ref.current.contains(event.target) && !parentRef.current.contains(event.target)) {
      cb();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}


function getPath(path, languageList) {
  const [, base, urlPath] = path.split('/');

  if (languageList.includes(base)) {
    return `/${urlPath || ''}`;
  }
  return `/${base || ''}`;
}

function LanguageSwitcher({ className, forSimpleHeader, inverted = false }) {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const { locale, config } = useLocalization();
  const parentRef = useRef(null);
  useOutsideAlerter(wrapperRef, parentRef, () => setOpen(false));

  const toggleMenu = () => {
    setOpen(!open);
  };

  if (typeof window === 'undefined') {
    return null;
  }

  const currentPath = useMemo(() => {
    if (typeof window === 'undefined') return '/';
    return getPath(window.location.pathname, config.map(c => c.code));
  }, [config, window.location.pathname]);

  return (
    <div className={classnames('globe-section ml-2', { inverted })}>
      <div role="button" onClick={toggleMenu} ref={parentRef}>
        <p className={classnames('language-text mb-0', className)} > {/* isSticky ? 'header header-transparent fixed-top scrolled' : 'header header-transparent fixed-top' */}
          {locale} <span className="ml-1"><FontAwesomeIcon icon={faCaretDown} /></span>
        </p>
      </div>
      { open && (
        <div className="language-menu" ref={wrapperRef}>
          {config.map(lng => (
            <LocalizedLink
              key={lng.code}
              to={currentPath}
              language={lng.code}
              >
              <div>{lng.localName}</div>
            </LocalizedLink>
          ))}
        </div>
      )}
    </div>
  );
}

LanguageSwitcher.propTypes = {
  forSimpleHeader: PropTypes.bool,
  inverted: PropTypes.bool,
};

export default LanguageSwitcher;
