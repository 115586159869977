/* global fbq */
import React, { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Modal, ModalHeader,
  ModalBody, Input, InputGroupAddon,
  Spinner, InputGroup, FormFeedback,
  InputGroupText, Form, FormGroup, Label,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import config from '../config';


const DemoFormSchema = Yup.object().shape({
  name: Yup.string()
    .required('Obligatorio'),
  email: Yup.string()
    .email('correo invalido')
    .required('Obligatorio'),
  phone: Yup.string()
    .required('Obligatorio'),
  company: Yup.string()
    .required('Obligatorio'),
  occupation: Yup.string(),
  contractsPerMonth: Yup.number().required('Obligatorio'),
  source: Yup.string()
    .required('Obligatorio'),
  message: Yup.string()
    .required('Obligatorio'),
});

function DemoForm({
  type = 'demo',
  formik: {
    isSubmitting, errors, touched, values, handleChange, handleSubmit,
  },
  formButtonLabel,
}) {
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);

  function onSubmit() {
    handleSubmit();
    const { email } = values;
    if (type === 'landing') {
      fbq('trackSingleCustom', config.fbPixels.tratoHomeForm, 'Registro_Demo_completado_Home', { email });
    }
    if (type === 'trato-education') {
      fbq('trackSingleCustom', config.fbPixels.tratoEducationForm, 'Registro_Demo_completado_Landing_Edu', { email });
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="name">
            {t('demoForm.name_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="name"
              name="name"
              type="text"
              placeholder={t('demoForm.placeholder1')}
              value={values.name}
              invalid={errors.name && touched.name}
              onChange={handleChange}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="email">
            {t('demoForm.email_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="email"
              type="email"
              name="email"
              placeholder={t('demoForm.placeholder2')}
              value={values.email}
              invalid={errors.email && touched.email}
              onChange={handleChange}
            />
            <FormFeedback>{errors.email}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold" for="phone">
            {t('demoForm.phone_label')}<span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="phone"
              name="phone"
              type="number"
              placeholder={t('demoForm.placeholder3')}
              value={values.phone}
              invalid={errors.phone && touched.phone}
              onChange={handleChange}
            />
            <FormFeedback>{errors.phone}</FormFeedback>
          </InputGroup>
        </FormGroup>
        <FormGroup>
          <Label className="font-weight-bold mb-1" for="company">
            {type === 'trato-education' ? t('demoForm.institution_label') : t('demoForm.company_label')}
            <span className="text-primary">*</span>
          </Label>
          <InputGroup className="mb-1">
            <InputWithLoader
              loading={isSubmitting}
              id="company"
              name="company"
              type="text"
              placeholder={type === 'trato-education' ? t('demoForm.placeholder9') : t('demoForm.placeholder4')}
              value={values.company}
              invalid={errors.company && touched.company}
              onChange={handleChange}
            />
            <FormFeedback>{errors.company}</FormFeedback>
          </InputGroup>
        </FormGroup>
        {/* { ['demo', 'trato-clm', 'trato-vs-docusign'].includes(type) &&  */}
        {!['demo-500', 'legalAdvisor', 'trato-education'].includes(type)
          && (
            <FormGroup>
              <Label className="font-weight-bold mb-1" for="source">
                {t('demoForm.referral_label')}<span className="text-primary">*</span>
              </Label>
              <InputGroup className="mb-1">
                <InputWithLoader
                  loading={isSubmitting}
                  id="source"
                  name="source"
                  type="text"
                  placeholder={t('demoForm.placeholder7')}
                  value={values.source}
                  invalid={errors.source && touched.source}
                  onChange={handleChange}
                />
                <FormFeedback>{errors.source}</FormFeedback>
              </InputGroup>
            </FormGroup>
          )}
        {type === 'legalAdvisor'
          && (
            <FormGroup>
              <Label className="font-weight-bold mb-1" for="source">
                {t('demoForm.information3_label')}  <span className="text-primary">*</span>
              </Label>
              <InputGroup className="mb-1">
                <Input
                  type="select"
                  id="whyHelp"
                  name="whyHelp"
                  placeholder={t('demoForm.placeholder8')}
                  value={values.whyHelp}
                  onChange={handleChange}
                >
                  <option value="Elaborar contrato">{t('demoForm.option1')}</option>
                  <option value="Revisar contrato">{t('demoForm.option2')}</option>
                  <option value="Modificar contrato existente">{t('demoForm.option3')}</option>
                  <option value="Otro">{t('demoForm.option4')}</option>
                </Input>
                {/* <FormFeedback>{errors.contractsPerMonth}</FormFeedback> */}
              </InputGroup>
            </FormGroup>
          )}
        {type === 'demo-500'
        && (
          <FormGroup>
            <Label className="font-weight-bold" for="source">
              {t('demoForm.information2_label')}  <span className="text-primary">*</span>
            </Label>
            <InputGroup className="mb-1">
              <InputWithLoader
                loading={isSubmitting}
                id="contractsPerMonth"
                name="contractsPerMonth"
                type="number"
                placeholder={t('demoForm.placeholder6')}
                value={values.contractsPerMonth}
                invalid={errors.contractsPerMonth && touched.contractsPerMonth}
                onChange={handleChange}
                />
              <FormFeedback>{errors.contractsPerMonth}</FormFeedback>
            </InputGroup>
          </FormGroup>
        )}
        {type === 'trato-education' && (
        <FormGroup className="mb-3">
          <Label className="font-weight-bold mb-1" for="source">
            {t('demoForm.occupation_label')}
          </Label>
          <InputGroup>
            <InputWithLoader
              loading={isSubmitting}
              id="occupation"
              name="occupation"
              type="text"
              placeholder={t('demoForm.placeholder10')}
              value={values.occupation}
              onChange={handleChange}
              />
            <FormFeedback>{errors.occupation}</FormFeedback>
          </InputGroup>
        </FormGroup>
        )}
        {type !== 'trato-education' && (
          <FormGroup>
            <Label className="font-weight-bold mb-1" for="message">
              {t('demoForm.information_label')} <span className="text-primary">*</span>
            </Label>
            <InputGroup className="mb-1">
              <InputWithLoader
                loading={isSubmitting}
                type="textarea"
                className="form-control"
                placeholder={t('demoForm.placeholder5')}
                value={values.message}
                onChange={handleChange}
                invalid={errors.message && touched.message}
                name="message"
                id="message"
              />
              <FormFeedback>{errors.message}</FormFeedback>
            </InputGroup>
          </FormGroup>
        )}
      </Form>
      <div className="text-center">
        <button type="submit" onClick={onSubmit} className="button orange animate-up w-100" disabled={isSubmitting}>
          {formButtonLabel || t('demoForm.button')}
        </button>
      </div>
    </>
  );
}

const DemoFormContainer = (props) => {
  const color = (props.color != undefined) ? '#344e5b' : '#f47b33';
  const {
    buttonLabel = (props.text) ? props.text : '',
    className,
    type = 'demo',
    onlyForm,
    size = 'md',
    inverted,
    buttonClassName = 'button animate-up mb-2 mr-1 text-center ',
    formButtonLabel,
  } = props;
  const { t } = useTranslation('home');
  const [isOpen, setIsOpen] = useState(false);
  const [succeed, setSucceed] = useState(false);
  const initialValues = useMemo(() => {
    const values = {
      name: '',
      email: '',
      phone: '',
      company: '',
      occupation: '',
      contractsPerMonth: '',
      source: '',
      message: '',
      whyHelp: 'Elaborar contrato',
    };
    if (type === 'legalAdvisor') {
      values.source = 'legalAdvisor';
    }
    if (type === 'eSignature') {
      values.source = 'eSignature';
    } 
    if (type === 'demo-500') {
      values.source = '500 startups';
    } 
    if (type === 'trato-education') {
      values.source = 'Landing education';
      values.message = 'Short Landing Page Education';
      values.contractsPerMonth = 0;
    } else {
      values.contractsPerMonth = 0;
    }
    return values;
  }, [type]);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, actions) => {
      values.type = type;

      const sent = await sendMessage(values);

      trackCustomEvent({
        category: 'Solicita una demo',
        action: 'Enviar información demo',
        label: values.type,
        value: `${values.name} - ${values.company}`,
      });

      actions.setSubmitting(false);
      actions.resetForm({ ...initialValues });
    },
    validationSchema: DemoFormSchema,
  });

  const toggle = () => {
    formik.resetForm();
    setIsOpen(open => !open);

    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: 'Solicita una demo',
      // string - required - Type of interaction (e.g. 'play')
      action: 'Boton landing page',
    });
  };

  const sendMessage = async (values) => {
    const response = await fetch(`${process.env.GATSBY_API_URL}/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      toast.success('Te enviamos un correo donde podras elegir el horario de la demo', {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'success',
      });
      setIsOpen(false);
      // setSucceed(true)
    } else {
      toast.error('Error en el envio.', {
        position: toast.POSITION.TOP_RIGHT,
        toastId: 'error',
      });
    }
  };

  if (onlyForm) {
    return <DemoForm type={type} formik={formik} formButtonLabel={formButtonLabel}/>;
  }


  return (
    <>
      <Button className={classnames(`${buttonClassName}`, { inverted })} style={{backgroundColor: color, borderRadius: '10px'}} size={size} onClick={toggle}> {buttonLabel || t('demoForm.title')}</Button>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalHeader className="text-center" toggle={toggle}>
          {t('demoForm.title')}
          <small className="d-block text-muted">{t('demoForm.subtitle')} </small>
        </ModalHeader>
        <ModalBody className="py-2 px-5">
          {
            succeed
              ? <div>{t('demoForm.succeed')}</div>
              : <DemoForm type={type} formik={formik} formButtonLabel={formButtonLabel} />
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export function InputWithLoader({ loading = false, disabled, ...props }) {
  return (
    <>
      <Input
        {...props}
        disabled={disabled || loading}
      />
      {loading && (
      <InputGroupAddon addonType="append">
        <InputGroupText>
          <Spinner
            type="border"
            color="secondary"
            role="status"
            style={{ borderWidth: '1.5px', width: '1rem', height: '1rem' }}
              />
        </InputGroupText>
      </InputGroupAddon>
      )}
    </>
  );
}

DemoForm.propTypes = {
  type: PropTypes.string,
  formik: PropTypes.shape({
    isSubmitting: PropTypes.bool,
    errors: PropTypes.any,
    touched: PropTypes.any,
    values: PropTypes.any,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.any,
  }),
  formButtonLabel: PropTypes.string,
};

DemoFormContainer.propTypes = {
  buttonLabel: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onlyForm: PropTypes.bool,
  size: PropTypes.string,
  inverted: PropTypes.any,
  buttonClassName: PropTypes.string,
  formButtonLabel: PropTypes.string,
};

InputWithLoader.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};


export default DemoFormContainer;
