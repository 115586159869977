import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import favicon from '../../static/favicon.ico';

function SEO(props) {
  const data = useStaticQuery(detailsQuery);
  const { title } = data.site.siteMetadata;
  const openGraph = props.opengraph || {};

  return (
    <Helmet
      htmlAttributes={{
        lang: 'es',
      }}
      title={props.title || title}
      titleTemplate={`${title} | %s`}
      meta={props.meta}
    >
      <link rel="shortcut icon" type="image/png" href={favicon} />
      {/* Facebook */}
      <meta property="og:title" content={openGraph.title || 'Trato'} />
      <meta property="og:type" content={openGraph.type || 'website'} />
      <meta property="og:description" content={openGraph.description || ''} />
      <meta property="og:url" content={openGraph.url || 'https://www.trato.io'} />
      <meta property="og:image" content={openGraph.imageUrl || 'https://trato-images.s3.amazonaws.com/open-graph-trato.jpg'} />}
      {/* <meta property="og:image:secure_url" content="https://trato-images.s3.amazonaws.com/open-graph-trato.jpg" /> */}
      <meta property="og:image:type" content={openGraph.imageMimeType || 'image/jpeg'} />
      <meta property="og:image:width" content={openGraph.imageWxidth || '1200'} />
      <meta property="og:image:height" content={openGraph.imageHeight || '1200'} />
      <meta property="og:image:alt" content="Trato" />
      <meta property="fb:app_id" content="Trato" />
      <meta name="slack-app-id" content="AM89E1J3A"/>
    </Helmet>

  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
