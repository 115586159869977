// 	var script = document.createElement('script'),
//     scripts = document.getElementsByTagName('script')[0];
// script.src = url;
// scripts.parentNode.insertBefore(script, scripts);
import { useLayoutEffect, useEffect } from "react";
function useScriptV2({ src, isDisabled, locale }) {
  useEffect(() => {
    const script = document.createElement("script");
    if (!isDisabled) {
      script.src = src;
      script.async = true;
      script.defer = true;
      console.log("useScriptV2");
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);

      const leadboosterContainer = document.getElementById(
        "LeadboosterContainer"
      );
      console.log("leadboosterContainer", leadboosterContainer);
      if (leadboosterContainer) {
        leadboosterContainer.remove();
      }
    };
  }, [src, isDisabled]);
}
export default useScriptV2;
