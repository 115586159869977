import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DemoForm from '../components/DemoForm';

function DemoModal({ modal, handleModal, type }) {
  return (
    <Modal isOpen={modal} className="demoModal">
      <ModalBody>
        <div
          class="pipedriveWebForms"
          data-pd-webforms="https://webforms.pipedrive.com/f/310dnojeGkfcA4xzzUfW7jhAICWVvnYGnE31QtchMVTozZp3bf5jYXLvy5Fl3bl8T"
        >
          <Helmet>
            <script src="https://webforms.pipedrive.com/f/loader"></script>
          </Helmet>
        </div>

        {/* <DemoForm onlyForm type={type} /> */}
      </ModalBody>

      <Button
        onClick={handleModal}
        style={{
          backgroundColor: '#f47b33',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,

          border: 0,
        }}
      >
        Cerrar
      </Button>
    </Modal>
  );
}

export default DemoModal;
